import { Button, Stack } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RelatedPersonMeta, relatedPeopleQuery } from '../../../../api/relatedPerson';
import Placeholder from '../../../../components/Placeholder';
import { RelatedPeopleTable } from '../../../../components/relatedPeople/RelatedPeopleTable';
import RelatedPersonDrawer from '../../../../components/relatedPeople/RelatedPersonDrawer';
import PageLayout from '../../PageLayout';
import { useWorkspace } from '../../workspaceContext';

const AssociatedPeople: FC = () => {
    const workspace = useWorkspace();

    // Manage the "add party" drawer state manually so that we can preserve the
    // form state between opening/closing the drawer and prevent the user losing
    // their work if they accidentally click the backdrop.
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingPerson, setEditingPerson] = useState<RelatedPersonMeta | undefined>(undefined);

    const navigate = useNavigate();

    const relatedPeopleQueryResult = useQuery(relatedPeopleQuery(workspace.id));
    const relatedPeople = relatedPeopleQueryResult.data?.results ?? [];

    return (
        <PageLayout
            title="Associated People"
            actions={
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                        setEditingPerson(undefined);
                        setIsDrawerOpen(true);
                    }}
                >
                    Add Person
                </Button>
            }
        >
            <RelatedPeopleTable
                relatedPeople={relatedPeople}
                rowProps={{
                    onClick: (person) => {
                        setIsDrawerOpen(true);
                        setEditingPerson(person);
                    },
                }}
                placeholder={
                    <Placeholder
                        title="No Associated People yet"
                        description="Add people related to the estate and manage their identity documents"
                    />
                }
            />
            <Stack direction="row" justifyContent="flex-end">
                <Button
                    variant="contained"
                    size="large"
                    startIcon={<IconArrowRight />}
                    onClick={() => navigate(`/workspace/${workspace.id}`)}
                >
                    Next step
                </Button>
            </Stack>
            <RelatedPersonDrawer
                open={isDrawerOpen}
                workspace={workspace}
                person={editingPerson}
                onCreated={(person) => setEditingPerson(person)}
                onClose={() => setIsDrawerOpen(false)}
                keepMounted
            />
        </PageLayout>
    );
};

export default AssociatedPeople;
