// Copyright (C) 2024, Affinda

import * as Sentry from '@sentry/react';
// import 'virtual:vite-plugin-sentry/sentry-config';

declare const __APP_NAME__: string;

export function initSentry(dsn: string, appName?: string) {
    const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.dist;
    const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release;

    if (!release || !dsn) return;

    Sentry.init({
        dsn,
        dist,
        release,
        integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],
        allowUrls: [/https?:\/\/(.+\.)?estatexchange\.com\.au/],
        ignoreErrors: ['AbortError', 'RestError', 'AxiosError'],
    });

    Sentry.setTag('app', appName ?? __APP_NAME__);
}
