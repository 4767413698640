import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormHelperText,
    Grid,
    InputAdornment,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import {
    CreateLookupGoRError,
    ProofOfAuthorityTask,
    lookupGoRMutation,
    lookupGoRRequest,
    uploadGoRMutation,
    uploadGoRRequest,
} from '../../../api/proofOfAuthority';
import { queryClient } from '../../../api/queryClient';
import { workspaceQuery } from '../../../api/workspace';
import FormGrid from '../../../components/FormGrid';
import DragAndDropFileField from '../../../components/fields/DragAndDropFileField';
import StateSelectField from '../../../components/fields/StateSelectField';
import { useWorkspace } from '../workspaceContext';

interface UploadGoRDialogProps extends DialogProps {
    onClose: () => void;
}

function UploadGoRDialog({ onClose, ...dialogProps }: UploadGoRDialogProps) {
    const workspace = useWorkspace();

    const lookupGoR = useMutation<
        ProofOfAuthorityTask,
        AxiosError<CreateLookupGoRError, any>,
        lookupGoRRequest,
        any
    >(lookupGoRMutation());
    const lookupGoRError =
        lookupGoR.error?.response?.data?.type === 'validation_error' &&
        lookupGoR.error?.response?.data?.errors[0].detail;

    const uploadGoR = useMutation<
        ProofOfAuthorityTask,
        AxiosError<CreateLookupGoRError, any>,
        uploadGoRRequest,
        any
    >(uploadGoRMutation());
    const uploadGoRError =
        uploadGoR.error?.response?.data?.type === 'validation_error' &&
        uploadGoR.error?.response?.data?.errors[0].detail;

    const handleSubmit = (values: any) => {
        if (values.state === 'VIC') {
            lookupGoR
                .mutateAsync(
                    {
                        workspace: workspace.id,
                        application_number: 'S PRB ' + values.application_number,
                        unique_identifier: values.unique_identifier,
                    },
                    {
                        onError: (e: any) => {
                            if (e.response.status === 400) {
                                e.preventAlert?.();
                            }
                        },
                    }
                )
                .then(() => {
                    queryClient.invalidateQueries(workspaceQuery(workspace.id));
                    enqueueSnackbar('Grant of representation retrieved.', { variant: 'success' });
                    onClose();
                });
        } else {
            uploadGoR
                .mutateAsync(
                    {
                        workspace: workspace.id,
                        file: values.gor,
                        jurisdiction: values.state,
                    },
                    {
                        onError: (e: any) => {
                            if (e.response.status === 400) {
                                e.preventAlert?.();
                            }
                        },
                    }
                )
                .then(() => {
                    queryClient.invalidateQueries(workspaceQuery(workspace.id));

                    enqueueSnackbar('Grant of representation uploaded.', { variant: 'success' });
                    onClose();
                });
        }
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            state: '',
            application_number: '',
            unique_identifier: '',
            gor: null,
        },
    });

    const { watch, setValue } = formMethods;

    const watchState = watch('state');
    const watchApplicationNumber = watch('application_number');

    useEffect(() => {
        var formatted = watchApplicationNumber?.replace(' ', '');

        if (formatted?.length > 4) {
            formatted = `${formatted.slice(0, 4)} ${formatted.slice(4)}`;
        }

        setValue('application_number', formatted);
    }, [watchApplicationNumber]);

    return (
        <Dialog onClose={onClose} {...dialogProps} maxWidth="md" fullWidth>
            <DialogTitle>Add grant of representation</DialogTitle>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    <FormGrid>
                        <Grid item xs={6}>
                            <StateSelectField
                                id="state"
                                name="state"
                                label="State or territory"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} />

                        {watchState === 'VIC' && (
                            <>
                                <Grid item xs={6}>
                                    <TextFieldElement
                                        name="application_number"
                                        label="Application number"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    S PRB{' '}
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldElement
                                        name="unique_identifier"
                                        label="Unique identifier"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </>
                        )}

                        {watchState !== undefined && watchState !== 'VIC' && (
                            <Grid item xs={12}>
                                <DragAndDropFileField
                                    name="gor"
                                    label="Grant of representation"
                                    required
                                />
                            </Grid>
                        )}
                    </FormGrid>
                </DialogContent>

                <DialogActions>
                    <FormHelperText error>{lookupGoRError || uploadGoRError}</FormHelperText>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={lookupGoR.status === 'pending' || uploadGoR.status === 'pending'}
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default UploadGoRDialog;
