const appSettingsElement = document.getElementById('app-settings');

const appSettingsJson = appSettingsElement?.getAttribute('data-settings') || '{}';

const appSettingsSnakeCase = JSON.parse(appSettingsJson);

export const appSettings = {
    auth0Enabled: appSettingsSnakeCase.auth0_enabled,
    showDisclaimer: appSettingsSnakeCase.show_disclaimer,
    addressfinderKey: appSettingsSnakeCase.addressfinder_key,
    sentryDSN: appSettingsSnakeCase.sentry_dsn,
    releaseVersion: appSettingsSnakeCase.release_version,
};
