import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { IconArrowRight, IconPencil } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { deceasedDetailQuery, updateDeceasedDetail } from '../../../api/deceasedDetail';
import { deleteGenericDocumentMutation } from '../../../api/genericDocument';
import { deletePoADocumentMutation, uploadPoADocument } from '../../../api/proofOfAuthority';
import { queryClient } from '../../../api/queryClient';
import {
    RelatedPersonMeta,
    relatedPeopleQuery,
    relatedPersonRoleLabels,
} from '../../../api/relatedPerson';
import { workspaceQuery } from '../../../api/workspace';
import { Card } from '../../../components/Card';
import InfoTooltip from '../../../components/InfoTooltip';
import QueryProgress from '../../../components/QueryProgress';
import UploadDropArea from '../../../components/UploadDropArea';
import UploadGenericDocumentDialog from '../../../components/UploadGenericDocumentDialog';
import UploadPlaceholder from '../../../components/UploadPlaceholder';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import {
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowJurisdiction,
    DocumentTableRowPoAUploadedBy,
    DocumentTableRowType,
} from '../../../components/documentsTable/DocumentsTableCells';
import RadioButtonGroupField from '../../../components/fields/RadioButtonGroupField';
import RelatedPersonDrawer from '../../../components/relatedPeople/RelatedPersonDrawer';
import { useStore } from '../../../store/storeContext';
import { nullableBooleanToYesNo } from '../../../utils';
import { characterLimit, noSpecialChars, optionalString } from '../../../validationRules';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import UploadGoRDialog from './UploadGoRDialog';

function ProofOfAuthority() {
    return (
        <PageLayout title="Proof of Authority">
            <ProofOfAuthorityContent />
        </PageLayout>
    );
}

function ProofOfAuthorityContent() {
    const navigate = useNavigate();

    const workspace = useWorkspace();

    const detailsQuery = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));
    const details = detailsQuery.data;

    const peopleQuery = useQuery(relatedPeopleQuery(workspace.id));
    const personalRepresentatives =
        peopleQuery.data?.results?.filter((person) => person.is_lpr) || [];

    const updateMutation = useMutation(updateDeceasedDetail());

    // Manage the "add party" drawer state manually so that we can preserve the
    // form state between opening/closing the drawer and prevent the user losing
    // their work if they accidentally click the backdrop.
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingPerson, setEditingPerson] = useState<RelatedPersonMeta | undefined>(undefined);

    const onSubmit = () => {
        const values = formMethods.getValues();
        updateMutation.mutate(
            {
                id: details?.id,
                has_will: values.has_will,
                will_additional_details: values.other_will_detail,
                gor_status: values.intention_to_apply_for_gor,
                has_other_poa: values.other_proof_of_authority_held === 'yes' ? true : false,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Changes saved.', { variant: 'success' });
                    navigate(`/workspace/${workspace.id}/associated-people`);
                },
            }
        );
    };

    const updateAddDetailsIntention = (addDetails: boolean) => {
        updateMutation.mutate({
            id: details?.id,
            add_details: addDetails,
        });
    };

    const validationSchema = z
        .object({
            has_will: z.enum(['yes', 'no', 'unknown', 'yes_but']),
            other_will_detail: optionalString.and(characterLimit(300)).and(noSpecialChars),
            intention_to_apply_for_gor: z.enum([
                'unsure',
                'no_intention',
                'not_applied',
                'pending',
                'approved',
            ]),
            other_proof_of_authority_held: optionalString,
            // Put a dummy values here so that we can attach errors to non-field elements
            personal_representatives: optionalString,
            will: z.string(),
            gor: optionalString,
            other_proof_of_authority: z.string(),
        })

        .refine(
            // At least one personal representative is required
            (fields) => personalRepresentatives && personalRepresentatives?.length > 0,
            {
                message: 'This field is required',
                path: ['personal_representatives'],
            }
        )
        .refine(
            // If has will is 'yes' or 'yes_but', then workspace must have a will
            (fields) =>
                (fields.has_will !== 'yes' && fields.has_will !== 'yes_but') || !!workspace.will,
            {
                message: 'Document upload required',
                path: ['will'],
            }
        )
        .refine(
            // If has will is 'no' or 'yes_but', then workspace must have other will
            (fields) =>
                (fields.has_will !== 'yes_but' && fields.has_will !== 'no') ||
                !!fields.other_will_detail?.length,
            {
                message: 'This field is required',
                path: ['other_will_detail'],
            }
        )
        .refine(
            // If intention to apply for gor is 'pending' or 'approved', then workspace must have a gor
            (fields) =>
                (fields.intention_to_apply_for_gor !== 'pending' &&
                    fields.intention_to_apply_for_gor !== 'approved') ||
                !!workspace.grants_of_representation,
            {
                message: 'Document upload required',
                path: ['gor'],
            }
        )
        .refine(
            // If other proof of authority held is 'yes', then workspace must have other proof of authority
            (fields) =>
                fields.other_proof_of_authority_held !== 'yes' ||
                !!workspace.other_poa_docs?.length,

            {
                message: 'Document upload required',
                path: ['other_proof_of_authority'],
            }
        );

    const formMethods = useForm({
        mode: 'onChange',
        progressive: true,
        defaultValues: {
            has_will: details?.has_will || undefined,
            other_will_detail: details?.will_additional_details || '',
            intention_to_apply_for_gor: details?.gor_status || undefined,
            other_proof_of_authority_held: nullableBooleanToYesNo(details?.has_other_poa) || '',
            personal_representatives: '',
            will: '',
            gor: '',
            other_proof_of_authority: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const { errors } = formMethods.formState;

    return (
        <QueryProgress query={detailsQuery}>
            <RelatedPersonDrawer
                open={isDrawerOpen}
                workspace={workspace}
                person={editingPerson}
                onCreated={(person) => setEditingPerson(person)}
                onClose={() => setIsDrawerOpen(false)}
                assumeLpr
            />
            <FormContainer
                formContext={formMethods}
                onSuccess={onSubmit}
                FormProps={{
                    id: 'proof-of-authority-form',
                }}
            >
                <Stack gap={5}>
                    <FormControl>
                        <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                            Do you wish to add details of the estate?
                            <InfoTooltip
                                title="You may not wish to complete this detail where it is known the deceased did not leave an estate."
                                sx={{ ml: 1 }}
                            />
                        </FormLabel>
                        <RadioGroup defaultValue={details?.add_details}>
                            <FormControlLabel
                                value={true}
                                onClick={() => updateAddDetailsIntention(true)}
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value={false}
                                onClick={() => updateAddDetailsIntention(false)}
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                    {details?.add_details && (
                        <>
                            <WillCard />
                            <LegalPersonRepresentativesCard
                                setIsDrawerOpen={setIsDrawerOpen}
                                setEditingPerson={setEditingPerson}
                            />
                            <GoRCard />
                            <OtherProofOfAuthorityCard />
                        </>
                    )}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{
                        bottom: 0,
                        zIndex: 3,
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        size="large"
                        startIcon={<IconArrowRight />}
                        form="proof-of-authority-form"
                    >
                        Next step
                    </LoadingButton>
                </Stack>
            </FormContainer>
        </QueryProgress>
    );
}

interface LegalPersonRepresentativesCardProps {
    setIsDrawerOpen: (isOpen: boolean) => void;
    setEditingPerson: (person: RelatedPersonMeta | undefined) => void;
}

function LegalPersonRepresentativesCard({
    setIsDrawerOpen,
    setEditingPerson,
}: LegalPersonRepresentativesCardProps) {
    const workspace = useWorkspace();

    const peopleQuery = useQuery(relatedPeopleQuery(workspace.id));
    const personalRepresentatives =
        peopleQuery.data?.results.filter((person) => person.is_lpr) || [];

    const {
        trigger,
        formState: { errors },
    } = useFormContext();

    // Retrigger field validation when the list of personal representatives changes
    useUpdateEffect(() => {
        trigger('personal_representatives');
    }, [personalRepresentatives?.length]);

    return (
        <Card title="Personal Representatives">
            <Stack gap={1}>
                <QueryProgress query={peopleQuery}>
                    {personalRepresentatives?.length ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell sx={{ width: '3.5rem' }} />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {personalRepresentatives.map((person) => (
                                        <TableRow
                                            key={person.id}
                                            sx={{
                                                // position: relative so that a button pseudo-element can cover
                                                // the entire row. But this is buggy in Safari and requires a
                                                // workaround using clip-path.
                                                // https://github.com/w3c/csswg-drafts/issues/1899#issuecomment-1232707455
                                                position: 'relative',
                                                clipPath: 'inset(0)',
                                            }}
                                        >
                                            <TableCell>{person.full_name}</TableCell>
                                            <TableCell>
                                                {person.role === 'other'
                                                    ? person.role_detail
                                                    : relatedPersonRoleLabels[person.role]}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    disableRipple
                                                    sx={{
                                                        position: 'static',
                                                        'tr:not(:hover) &:not(:focus-visible)': {
                                                            opacity: 0,
                                                        },
                                                        '&::before': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            inset: 0,
                                                            zIndex: 1,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setIsDrawerOpen(true);
                                                        setEditingPerson(person);
                                                    }}
                                                >
                                                    <IconPencil />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                </QueryProgress>
                <Stack>
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ maxWidth: 'fit-content' }}
                        onClick={() => {
                            setEditingPerson(undefined);
                            setIsDrawerOpen(true);
                        }}
                    >
                        Add Personal Representative
                    </Button>

                    {errors.personal_representatives && (
                        <FormHelperText
                            error
                            sx={{ ml: 1.5 }}
                        >{`${errors.personal_representatives?.message}`}</FormHelperText>
                    )}
                </Stack>
            </Stack>
        </Card>
    );
}

function GoRCard() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const deleteMutation = useMutation(deletePoADocumentMutation());

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            },
        });
    };

    const {
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const watchIntentionToApply = watch('intention_to_apply_for_gor');

    useUpdateEffect(() => {
        trigger('gor');
    }, [workspace.grants_of_representation]);

    return (
        <Card title="Grant of representation">
            <Grid item xs={12}>
                <RadioButtonGroupField
                    name="intention_to_apply_for_gor"
                    required
                    label="Do you intend to apply for an Australian grant of representation?"
                    options={[
                        { id: 1, label: 'Not sure yet', value: 'unsure' },
                        { id: 2, label: 'No', value: 'no_intention' },
                        {
                            id: 3,
                            label: 'Yes - Intend to apply',
                            value: 'not_applied',
                        },
                        {
                            id: 4,
                            label: 'Yes - Application submitted',
                            value: 'pending',
                        },
                        {
                            id: 5,
                            label: 'Yes - Grant already issued',
                            value: 'approved',
                        },
                    ]}
                    valueKey="value"
                />
            </Grid>

            {watchIntentionToApply === 'approved' && (
                <>
                    <Grid item xs={6} pb={1}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => showModal(UploadGoRDialog)}
                        >
                            Add grant of representation
                        </Button>
                        {errors.gor ? (
                            <FormHelperText
                                error
                                sx={{ ml: 1.5 }}
                            >{`${errors.gor?.message}`}</FormHelperText>
                        ) : null}
                    </Grid>
                    {workspace.grants_of_representation?.length > 0 && (
                        <Grid item xs={12}>
                            <FormLabel error={!!errors.gor} required>
                                Grant of representation documents
                            </FormLabel>
                            <DocumentsTable
                                documents={[...workspace.grants_of_representation]}
                                onDelete={handleDelete}
                                columns={[
                                    {
                                        id: 'file',
                                        label: 'File',
                                        Component: DocumentTableRowFile,
                                    },
                                    {
                                        id: 'jursidiction',
                                        label: 'Jurisdiction',
                                        Component: DocumentTableRowJurisdiction,
                                    },
                                    {
                                        id: 'createdAt',
                                        label: 'Uploaded',
                                        Component: DocumentTableRowCreatedAt,
                                    },
                                    {
                                        id: 'uploadedBy',
                                        label: 'Uploaded by',
                                        Component: DocumentTableRowPoAUploadedBy,
                                    },
                                ]}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Card>
    );
}

function OtherProofOfAuthorityCard() {
    const workspace = useWorkspace();
    const deleteMutation = useMutation(deleteGenericDocumentMutation());
    const { showModal } = useModal();

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            },
        });
    };

    const {
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const watchOtherProofHeld = watch('other_proof_of_authority_held');

    useUpdateEffect(() => {
        trigger('other_proof_of_authority');
    }, [workspace.other_poa_docs]);

    return (
        <Card title="Other Proof of Authority">
            <RadioButtonGroupField
                name="other_proof_of_authority_held"
                label="Is other Proof of Authority held? "
                required
                options={[
                    { label: 'Yes', id: 1, value: 'yes' },
                    { label: 'No', id: 2, value: 'no' },
                ]}
                valueKey="value"
                infoTooltip="Include here any other independent documents which may be considered Proof of Authority for the above-named Personal Representatives to act for the estate."
            />
            {watchOtherProofHeld === 'yes' ? (
                <Box>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() =>
                            showModal(UploadGenericDocumentDialog, {
                                title: 'Upload Proof of Authority',
                                taskType: 'proof_of_authority',
                            })
                        }
                    >
                        Upload other Proof of Authority
                    </Button>
                    {errors.other_proof_of_authority && (
                        <FormHelperText
                            error
                            sx={{ ml: 1.5 }}
                        >{`${errors.other_proof_of_authority.message}`}</FormHelperText>
                    )}
                    <Stack gap={1} sx={{ pt: 4 }}>
                        {workspace.other_poa_docs.length > 0 ? (
                            <DocumentsTable
                                documents={[...workspace.other_poa_docs]}
                                onDelete={handleDelete}
                                extraColumns={[
                                    {
                                        id: 'type',
                                        label: 'Type',
                                        Component: DocumentTableRowType,
                                    },
                                ]}
                            />
                        ) : null}
                    </Stack>
                </Box>
            ) : null}
        </Card>
    );
}

function WillCard() {
    const workspace = useWorkspace();
    const store = useStore();

    const onUpload = (file: File) =>
        store.uploadDocument(
            file,
            (file: File) =>
                uploadPoADocument(
                    {
                        workspace: workspace.id,
                        poa_type: 'will',
                        jurisdiction: 'n/a',
                    },
                    file
                ),
            () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            }
        );

    const deleteMutation = useMutation(deletePoADocumentMutation());

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            },
        });
    };

    const {
        formState: { errors },
        trigger,
        watch,
    } = useFormContext();

    useUpdateEffect(() => {
        trigger('will');
    }, [workspace.will]);

    const watchHasWill = watch('has_will');

    return (
        <Card title="Will">
            <Stack>
                <SelectElement
                    name="has_will"
                    label="Did the deceased leave a will?"
                    options={[
                        { id: 1, label: 'Yes', value: 'yes' },
                        { id: 2, label: 'No', value: 'no' },
                        {
                            id: 3,
                            label: 'Currently unknown',
                            value: 'unknown',
                        },
                        { id: 4, label: 'Yes but...', value: 'yes_but' },
                    ]}
                    valueKey="value"
                    required
                    sx={{
                        width: '50%',
                    }}
                />
                {(watchHasWill === 'yes_but' || watchHasWill === 'no') && (
                    <TextFieldElement
                        multiline
                        name="other_will_detail"
                        label="Additional detail"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InfoTooltip
                                    title="Provide relevant detail to help Participants understand who has a claim on the estate."
                                    size={22}
                                    sx={{ mt: -2, mr: -0.25, opacity: 0.8 }}
                                />
                            ),
                        }}
                        sx={{
                            width: '50%',
                        }}
                    />
                )}
                {(watchHasWill === 'yes' || watchHasWill === 'yes_but') && (
                    <>
                        <FormLabel error={!!errors.will} required>
                            Will documents
                        </FormLabel>

                        {!workspace.will ? (
                            <UploadDropArea onUpload={onUpload}>
                                <UploadPlaceholder onUpload={onUpload} />
                            </UploadDropArea>
                        ) : null}

                        {!!workspace.will ? (
                            <DocumentsTable documents={[workspace.will]} onDelete={handleDelete} />
                        ) : null}

                        {errors.will ? (
                            <FormHelperText
                                error
                                sx={{ ml: 1.5 }}
                            >{`${errors.will?.message}`}</FormHelperText>
                        ) : null}
                    </>
                )}
            </Stack>
        </Card>
    );
}

export default ProofOfAuthority;
