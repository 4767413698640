import { RouteObject, redirect } from 'react-router-dom';
import { fetchUser } from '../api/user';
import Error from '../components/Error';
import Workspace, { loader as workspaceLoader } from '../pages/workspace/Workspace';
import Notes from '../pages/workspace/notes/Notes';
import { professionalRepIssues } from '../pages/workspace/requests/';
import Requests from '../pages/workspace/requests/Requests';
import AccountInstructions from '../pages/workspace/workspaceOwner/AccountInstructions';
import DeceasedInformation from '../pages/workspace/workspaceOwner/DeceasedInformation';
import CorrespondanceFinalisation from '../pages/workspace/workspaceOwner/Finalisation';
import { default as ProofOfAuthorityDataEntry } from '../pages/workspace/workspaceOwner/ProofOfAuthority';
import { default as ProofOfDeathDataEntry } from '../pages/workspace/workspaceOwner/ProofOfDeath';
import CorrespondanceStatementOfPosition from '../pages/workspace/workspaceOwner/StatementOfPosition';
import AssociatedPeople from '../pages/workspace/workspaceOwner/associatedPeople/AssociatedPeople';
import ParticipantDetail, {
    loader as participantDetailLoader,
} from '../pages/workspace/workspaceOwner/summary/ParticipantDetail';
import Participants from '../pages/workspace/workspaceOwner/summary/Summary';
const workspaceRoutes: RouteObject = {
    id: 'workspace',
    path: 'workspace/:workspaceId',
    element: <Workspace />,
    loader: async (loaderFunctionArgs) => {
        const {
            params: { workspaceId },
        } = loaderFunctionArgs;

        // Assume the user is a member of the workspace if their organisation is an institution
        const user = await fetchUser();
        if (!!user?.institution_organisation) {
            throw redirect(`/workspace/m/${workspaceId}`);
        }

        return workspaceLoader(loaderFunctionArgs);
    },
    children: [
        {
            errorElement: <Error />,
            children: [
                {
                    path: '',
                    element: <Participants />,
                },
                {
                    path: 'deceased-information',
                    element: <DeceasedInformation />,
                },
                {
                    path: 'pod-data-entry',
                    element: <ProofOfDeathDataEntry />,
                },
                {
                    path: 'poa-data-entry',
                    element: <ProofOfAuthorityDataEntry />,
                },
                {
                    path: 'associated-people',
                    element: <AssociatedPeople />,
                },
                {
                    path: 'participants/:participantId',
                    loader: participantDetailLoader,
                    element: <ParticipantDetail />,
                },
                {
                    path: 'account-instructions',
                    element: <AccountInstructions />,
                },
                {
                    path: 'requests',
                    element: (
                        <Requests issues={professionalRepIssues} userType="de_representative" />
                    ),
                },
                {
                    path: 'statement-of-position',
                    element: <CorrespondanceStatementOfPosition />,
                },
                {
                    path: 'finalisation',
                    element: <CorrespondanceFinalisation />,
                },
                {
                    path: 'notes',
                    element: <Notes />,
                },
            ],
        },
    ],
};

export default workspaceRoutes;
